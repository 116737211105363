import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import Layout from '../../components/Layout'
import Logo from '../../components/Header/logo'
import ScrollToTop from 'react-scroll-up'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {blog, contact_us} from '../../components/strings'
import AOS from 'aos';
import Helmet from 'react-helmet'

var slugify = require('slugify')

class BlogPostBs extends React.Component{
  componentDidMount(){
    AOS.init({
      duration : 500
    })
  }
  render(){
    const { markdownRemark: post } = this.props.data
        const { title, client, date, category, description, website, website_url, image } = post.frontmatter
        const {next,previous, bs_portfolio_blog} = this.props.pageContext
      
        if (typeof window !== 'undefined') {
            var currentSlug = window.location.pathname.split('/');
       
         currentSlug = currentSlug[3];
         }

  return (
    <Layout>

      <Helmet
          title={"WELL - BLOG | "+title}
          meta={[
            { name: 'description', content: 'U blogu WELL-a ćeš saznati više o trendovima modernih digitalno marketinških metoda kao i o najboljim online marketinškim instrumentima.' },
            {name: 'og:image', content: "https://www.wellpromotion.ba"+ image },
            {name: 'og:image:type', content: "image/jpg" },
            {name: 'og:type', content: "website" },
            {name: 'og:url', content: "https://wellpromotion.ba"},
            {name: 'twitter:card', content: "https://www.wellpromotion.ba"+ image },
            {name: 'twitter:image:alt', content: "Well Business Promotion Marketing Agency" },
          ]} >
        </Helmet>
      <div data-aos='fade-zoom-in'>
    <div id="s_header" className="section single-mobile-header is-fixed is-full-width is-padding-0">
         <ScrollToTop showUnder={10}
                 style={{
                     zIndex: 99,
                     top:0,
                     left:0,
                     height:'100px',
                     background: 'transparent',
                     cursor: 'default'
                 }}>
         <div className="header-back">
         
         </div>
         </ScrollToTop>
     
     <div className="container main-header is-flex">
         <div className="logo is-left">
             <Link to="/"><Logo/></Link>
         </div>
 
         <div className="navbar is-left">
             
         </div>
       
         <Link to={"/bs/blog"} className="contact-close is-right"><FontAwesomeIcon icon={['fas', 'times-circle']} aria-hidden="true" /> {blog}</Link>
     </div>
     </div>

      <section id="p_single" className="hero single-blog-post is-fullheight pages">
        <div className="hero-head"></div>
            <div className="hero-body is-padding-0">
                <div className="overlay_image"></div>
                <div className="overlay_color"></div>
                <div className="container">  
                    <div className="inner">
                        <div className="news-wrapper">
                        <h1 className="title">{title}</h1>
                            <div className="image">
                              <img src={image} alt={description} />
                            </div>
        
                            <div className="content">
                              
                                <div className="desc"dangerouslySetInnerHTML={{ __html: post.html }} />

                                <div class="order-box">
                                    <h2 className="margin-top-0"><i>Želite</i></h2>
                                    <p><FontAwesomeIcon icon={['far', 'check-square']} aria-hidden="true" /> kvalitetan sajt?</p>
                                    <p><FontAwesomeIcon icon={['far', 'check-square']} aria-hidden="true" /> dobru poziciju na Google-u?</p>
                                    <p><FontAwesomeIcon icon={['far', 'check-square']} aria-hidden="true" /> brendiranje firme, proizvoda ili usluga?</p>
                                    <Link to="/bs/kontakt" className="button gold">Kontaktirajte nas<span className="icon contact"></span></Link>
                                </div>
                                
                            </div>
                        </div>

                        <div className="sidebar">
                            <ul>
                                <li class="nav-section-label">Pročitajte također</li>

                                {bs_portfolio_blog.map(({ node }) => {
                                  var currentSlugEdited = slugify(node.frontmatter.title, {
                                    replacement: '-',  
                                    remove: null,    
                                    lower: true  
                                  })
                                  if(node.frontmatter.templateKey === "bs_blog")
                                  {
                                    if(currentSlug === currentSlugEdited)
                                    {
                                    return(
                                      <li class="page-collection active-link"><Link to={node.fields.slug} className="active">
                                      {node.frontmatter.title}</Link> 
                                      </li>
                                  )
                                    }
                                  else{
                                    return(
                                    <li class="page-collection"><Link to={node.fields.slug}>
                                    {node.frontmatter.title}</Link> 
                               
                                    </li>
                                    )
                                  }
                              
                                  }
                                })}   
                             
                            </ul>
                        </div>
                    </div>      
                    <ul className="pagination">
                    {previous && (
                      <li>
                        <Link to={previous.fields.slug} rel="prev">
                          ← {previous.frontmatter.title}
                        </Link>
                      </li>
                    )}

                    {next && (
                      <li>
                        <Link to={next.fields.slug} rel="next">
                          {next.frontmatter.title} →
                        </Link>
                      </li>
                    )}
                  </ul>
                </div>
            </div>
        <div className="hero-foot">
        </div>
    </section>

    </div>
    </Layout>
  )
}
}

BlogPostBs.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPostBs

export const pageBlogQueryBs = graphql`
  query pageBlogQueryBs($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date
        title
        image
        description
      }
    }
  }
`